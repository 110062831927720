<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-wrap py-3">
      <div class="card-title">
        <h3 class="card-label">
          Error data
        </h3>
      </div>
    </div>
    <div class="card-body">
      <div
        id="kt_datatable_wrapper"
        class="dataTables_wrapper dt-bootstrap4 no-footer"
      >
        <div class="row">
          <div class="col-sm-12">
            <v-data-table
              dense
              :headers="headers"
              :items="this.error_data"
              :options.sync="options"
              :loading="loading"
              :search="search"
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            >
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize">
                  Reset
                </v-btn>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { PULL_ERROR_DATA } from "@/core/services/store/xmlfeed.module";

export default {
  data() {
    return {
      search: "",
      options: {},
      headers: [
        { text: "Code", value: "sync_id" },
        { text: "Name", value: "name" },
        { text: "Version", value: "version" },
        { text: "Update time", value: "update_time" },
        { text: "Status", value: "status" },
        { text: "Request", value: "request", sortable: false },
        { text: "Response", value: "response", sortable: false }
      ]
    };
  },
  watch: {
    changeProject() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    ...mapState({
      errors: state => state.xmlfeed.errors,
      message: state => state.message.message,
      error_data: state => state.xmlfeed.error_data,
      loading: state => state.xmlfeed.loading
    }),
    ...mapGetters(["currentProject"])
  },
  methods: {
    reloadData() {
      if (this.$route.query.id !== undefined) {
        this.$store.dispatch(PULL_ERROR_DATA, this.$route.query.id);
      }
    }
  },
  mounted() {
    this.reloadData();
  }
};
</script>
